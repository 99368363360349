import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const CharactersPage = ({ location, data: { allContentfulCharacter } }) => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        pathname={location.pathname}
        title={intl.formatMessage({ id: 'characters-page-title' })}
        description={intl.formatMessage({ id: 'characters-page-description' })}
      />

      <div className="relative pt-24 w-11/12 max-w-screen-md mx-auto">
        <h1 className="font-bold text-2xl text-center mb-24">{intl.formatMessage({ id: 'characters-page-title' })}</h1>

        {allContentfulCharacter?.edges.map(({ node: character }, index) => (
          <article className="grid grid-cols-1 gap-8 mb-24 md:grid-cols-3 md:gap-x-16" key={character.id}>
            <div className={`w-full ${index % 2 !== 0 ? 'md:order-2' : ''}`}>
              <GatsbyImage image={character.image.gatsbyImageData} alt="" />
            </div>

            <div
              className="rich-text md:order-1 md:col-span-2"
              dangerouslySetInnerHTML={{ __html: character.description?.childMarkdownRemark?.html }}
            />
          </article>
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query CharactersPageQuery($locale: String!) {
    allContentfulCharacter(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          id
          name
          image {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default CharactersPage;
